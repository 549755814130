'use strict';

(function() {
  class ListSiteCtrl {
    constructor($scope, $rootScope, Auth, Util, Site, $state, usSpinnerService, appConfig, $window, $http, $timeout, ActivityLog) {
      this.$scope = $scope;
      this.$rootScope = $rootScope;
      this.$http = $http;
      this.$timeout = $timeout;
      this.Site = Site;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.isAdmin = Auth.isAdmin;
      this.allSites = [];
      this.loading = false;
      this.usSpinnerService = usSpinnerService;
      this.appConfig = appConfig;
      this.getAddress = Util.getAddress;
      this.getTotalCapacity = Util.getTotalCapacity;
      this.ActivityLog = ActivityLog;
      this.graphWidth = 250;
      this.graphHeight = 250;
      this.sorting = 'alpha';
      this.findingsCategory = {
        'cat0': false,
        'cat1': false,
        'cat2': false,
        'cat3': false,
        'cat4': false,
        'cat5': false,
      };

      let w = angular.element($window);

      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
          this.init();
        } else {
          $state.go('login');
        }
      });
      this.$scope.$on('siteAdded', (event, site) => {
        this.allSites.unshift(site);
      });

      $rootScope.$watch('findingsCategory' ,() => {
        this.filterSitesByFindingCategory($rootScope.findingsCategory);
      },true);

      $scope.getWindowDimensions = function() {
        return {
          'h': w.height(),
          'w': w.width()
        };
      };

      $scope.$watch($scope.getWindowDimensions, (newValue, oldValue) => {
        if (newValue.w < 768) {
          this.graphWidth = newValue.w - 40;
          this.graphHeight = 250;
        } else if (newValue.w >= 768 && newValue.w <= 992) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 992 && newValue.w <= 1200) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 1200 && newValue.w <= 1360) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else {
          this.graphWidth = 212;
          this.graphHeight = 250;
        }

        this.allSites.forEach(site=> {
          if(site) {
            // console.log('site.findingsCount : ', site.findingsCount);
            if(!site.findingsCount) {
              console.log('findingsCount not found');
            }
            site.totalFindingsCount = site.findingsCount.cat1 + site.findingsCount.cat2 + site.findingsCount.cat3 + site.findingsCount.cat4 + site.findingsCount.cat5;
            site.options = {
              chart: {
                type: 'pieChart',
                width: this.graphWidth,
                height: this.graphHeight,
                donut: true,
                title: site.totalFindingsCount,
                x: function(d) {
                  return d.key;
                },
                y: function(d) {
                  return d.y;
                },
                showLegend: true,
                showLabels: true,
                showValues: true,
                valueFormat: function(d) {
                  return d3.format(',.0f')(d);
                },
                labelType: function(d) {
                  return d.value
                },
                color: function(d, i) {
                  return (d && d.color);
                },
                duration: 500
              }
            };
          }
        });
      }, true);

      w.bind('resize', function() {
        $scope.$apply();
      });
    }

    filterSitesByFindingCategory(findingsCategory) {
      let isAnyCategorySelected = false;
        this.allSortedSites = _.filter(this.allSites, site => {
          if((findingsCategory.cat5 && site.findingsCount.cat5 > 0) || (findingsCategory.cat4 && site.findingsCount.cat4 > 0) || (findingsCategory.cat3 && site.findingsCount.cat3 > 0) || (findingsCategory.cat2 && site.findingsCount.cat2 > 0) || (findingsCategory.cat1 && site.findingsCount.cat1 > 0) || (findingsCategory.cat0 && site.findingsCount.cat0 > 0) ) {
            isAnyCategorySelected = true;
            return site;
          }
        });
        if(!isAnyCategorySelected) {
          this.allSortedSites = this.allSites;
          this.sortingSite();
        }
    }

    generateGraphData() {
      this.allSites.forEach(site=> {
        site.totalFindingsCount = site.findingsCount.cat1 + site.findingsCount.cat2 + site.findingsCount.cat3 + site.findingsCount.cat4 + site.findingsCount.cat5;
        site.options = {
          chart: {
            type: 'pieChart',
            width: this.graphWidth,
            height: this.graphHeight,
            donut: true,
            title: site.totalFindingsCount,
            x: function(d) {
              return d.key;
            },
            y: function(d) {
              return d.y;
            },
            showLegend: true,
            showLabels: true,
            showValues: true,
            valueFormat: function(d) {
              return d3.format(',.0f')(d);
            },
            labelType: function(d) {
              return d.value
            },
            color: function(d, i) {
              return (d && d.color);
            },
            duration: 500
          }
        };
        site._data = [
          {
            key: "Low",
            y: site.findingsCount.cat1,
            color: this.appConfig.colors.cat1
          },
          {
            key: "Medium",
            y: site.findingsCount.cat2,
            color: this.appConfig.colors.cat2
          },
          {
            key: "Serious",
            y: site.findingsCount.cat3,
            color: this.appConfig.colors.cat3
          },
          {
            key: "Major",
            y: site.findingsCount.cat4,
            color: this.appConfig.colors.cat4
          },
          {
            key: "Critical",
            y: site.findingsCount.cat5,
            color: this.appConfig.colors.cat5
          }
        ];
      });
    }

    init() {
      this.loading = true;
      this.Site.get({ skipTurbinePopulate: true }).$promise
        .then(response=> {
          if (response.data && response.data.length) {
            this.allSites = response.data;
            this.sortingSite();
            this.filterSitesByFindingCategory(this.$rootScope.findingsCategory);
            // this.generateGraphData();
            
            // enable collapse
            this.$timeout(()=>{
              jQuery('.projectbox').openClose({
                activeClass: 'projects-active',
                opener: '.opener',
                slider: '.holder',
                animSpeed: 400,
                effect: 'slide'
              });
              $('.archive-site .opener').click();
            }, 2000);
          }
          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err=> {
          console.log(err);
          this.loading = false;
          this.stopSpin('spinner-1');
        })
    }

    exportFindings(site) {
      this.$http({
        method: 'GET',
        url: "/api/v1/findings/export/site/"+ site._id,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
      .success((data, status, headers, config) => {
        // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
        saveAs(new Blob([data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), site.name+".xlsx");

        const activityLogData = {
          site: site._id,
          user: this.currentUser._id,
          source: 'web',
          action: 'export findings',
          type: 'site',
          sourceScreen: 'site',
          eventString: `Findings for Wind farm <a href="/site/${site._id}">${site.name}</a> was exported by ${this.currentUser.name}`
        }
        this.ActivityLog.save(activityLogData).$promise
          .then(activityLog => {
            console.log(activityLog);
          })
          .catch(err => {
            console.log('Error inserting activityLog', err);
        });
      })
      .error((data, status, headers, config) => {
        console.log(data);
      });
    }

    exportRepairs(site) {
      this.$http({
        method: 'GET',
        url: "/api/v1/repairs/export/site/"+ site._id,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
      .success((data, status, headers, config) => {
        // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
        saveAs(new Blob([data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), site.name+".xlsx");

        const activityLogData = {
          site: site._id,
          user: this.currentUser._id,
          source: 'web',
          action: 'export repairs',
          type: 'site',
          sourceScreen: 'site',
          eventString: `Repairs for Wind farm <a href="/site/${site._id}">${site.name}</a> was exported by ${this.currentUser.name}`
        }
        this.ActivityLog.save(activityLogData).$promise
          .then(activityLog => {
            console.log(activityLog);
          })
          .catch(err => {
            console.log('Error inserting activityLog', err);
        });
      })
      .error((data, status, headers, config) => {
        console.log(data);
      });
    }

    sortingSite(){
      var totalFindingCount = 0;
      this.allSortedSites = _.sortBy(this.allSites, (site)=>{
        totalFindingCount = 0;
        totalFindingCount = site.findingsCount.cat1 + site.findingsCount.cat2 + site.findingsCount.cat3 +site.findingsCount.cat4 + site.findingsCount.cat5;
        if(this.sorting == 'alpha') {
          return site.name;
        } else if(this.sorting == 'alpha_dec') {
          return site.name;
        } else if(this.sorting == 'mrf'){
          return -site.createdAt;
        } else if(this.sorting == 'of'){
          return site.createdAt;
        } else if(this.sorting == 'mfc'){
          return -totalFindingCount;
        }
      });
      if(this.sorting == 'alpha_dec') {
        this.allSortedSites = this.allSortedSites.reverse();
      }
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('ListSiteCtrl', ListSiteCtrl);
})();

